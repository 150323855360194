
import { Component, Watch } from '@/lib/decorator';
import { ref } from 'vue';

import Form from '@/layouts/Form';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';

import EmployeeStatisticsEntityModule from '@/store/employees/statisticsEntity';
import { strings } from '@/lib/stringConst';
import { searchAddress } from '@/api/geo';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { yandexMap } from './Index.vue';
import { Autocomplete } from '@/lib/formFactory/autocomplete.interface';
import { EmployeeStatisticModel } from '@/interfaces/models/employees.interface';

@Component({
  components: {
    ModalBtnBottom,
    FormInput,
    FormAutocomplete,
    FormButton,
  },
  emits: ['click'],
})
export default class EmployeePointsForm extends Form {
  @Watch('selectedAddress')
  async addressStringChangedInModel(newValue: { address: string; latitude: number; longitude: number }): Promise<void> {
    if (newValue.address !== '') {
      this.addressesLoading = true;
      this.address = { id: newValue.address, value: newValue.address };
      this.addressesItems = [
        {
          id: newValue.address,
          value: newValue.address,
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      ];

      await this.updateSelectedAddress({
        id: newValue.address,
        value: newValue.address,
        data: {
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      });
    }
  }

  form = ref(null);
  validate = formValidate(this.form);

  name = this.model.name ?? '';
  distance = this.model.distance ?? '';
  address: Autocomplete = this.model.address ?? { id: null, value: '' };
  addressesItems: { id: string; value: string; latitude: number; longitude: number }[] = [];
  addressesLoading = false;

  get text(): Record<string, string> {
    return strings;
  }

  get selectedAddress(): {} {
    return EmployeeStatisticsEntityModule.selectedAddress;
  }

  get model(): EmployeeStatisticModel {
    return EmployeeStatisticsEntityModule.model;
  }

  prepareResultSearchAddress(
    addresses: { address: string; lat: number; long: number }[]
  ): { id: string; value: string; latitude: number; longitude: number }[] {
    return addresses.map((address: { address: string; lat: number; long: number }) => {
      return {
        id: address.address,
        value: address.address,
        latitude: address.lat,
        longitude: address.long,
      };
    });
  }

  async searchAddress(value: string): Promise<void> {
    try {
      if (value.length === 0) {
        return;
      }

      this.addressesLoading = true;

      const result = await searchAddress(value);
      this.addressesItems = this.prepareResultSearchAddress(result);

      this.addressesLoading = false;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  updateAddress(e: { target: { value: string } }): void {
    this.updateSelectedAddress({ id: e.target.value, value: e.target.value });
  }

  async updateSelectedAddress(selected: {
    id: string;
    value: string;
    data?: { latitude: number; longitude: number };
  }): Promise<void> {
    if (!selected.value.trim().length) {
      return;
    }

    if (typeof selected.data !== 'undefined') {
      EmployeeStatisticsEntityModule.updateAddressCoordinates({
        latitude: selected.data.latitude,
        longitude: selected.data.longitude,
      });

      this.updatePointMap(selected.value, {
        lat: selected.data.latitude,
        lng: selected.data.longitude,
      });
    }
  }

  updatePointMap(address: string, coordinates: { lat: number; lng: number }): void {
    yandexMap.updatePlaceMarker([coordinates.lat, coordinates.lng]);
    yandexMap.entity.setCenter([coordinates.lat, coordinates.lng]);
    yandexMap.setPlaceMarkAddress(address);
  }

  async save(): Promise<void> {
    const valid = await this.validate.validate();

    if (!valid) return;

    try {
      await EmployeeStatisticsEntityModule.updatePoint({
        name: this.name,
        distance: this.distance,
        address: this.address.value as string,
      });

      this.$emit('click');
    } catch (e) {
      console.debug('error');
    }
  }

  async deletePoint(): Promise<void> {
    await this.blockQuery(async () => {
      try {
        await EmployeeStatisticsEntityModule.deletePoint();
        this.$emit('click');
      } catch (e) {
        console.debug('error');
      }
    });
  }
}
