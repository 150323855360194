
import { Component, Vue } from '@/lib/decorator';
import ModalBlock from '@/components/Modal.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import { GuiFormCheckbox, GuiLoader } from '@library/gigant_ui';

import TitleTab from '../_titleTab.vue';
import AddPoint from './_add.vue';
import EditPoint from './_edit.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormButton from '@/components/form/Button/index.vue';

import EmployeesModule from '@/store/employees';
import EmployeeEntityModule from '@/store/employees/entity';
import EmployeeStatisticsModule, { ModalParamsInterface } from '@/store/employees/statistics';
import { strings } from '@/lib/stringConst';
import EmployeeStatisticsEntityModule, {
  PointInterface,
  ShopListInterface,
  ShopStatus,
} from '@/store/employees/statisticsEntity';
import YandexMap from '@/lib/map/yandexMap';
import userModule from '@/store/user';

export let yandexMap: YandexMap = Object.create(null);

let suitableShopsMapHelper: YandexMap = Object.create(null);
let suitableShopsMap: YandexMap = Object.create(null);

@Component({
  components: {
    GuiFormCheckbox,
    TitleTab,
    ModalBlock,
    AppHeaderBtnAdd,
    AddPoint,
    EditPoint,
    IconFont,
    FormButton,
    GuiLoader,
  },
  beforeMount() {
    this.employeeId = this.$route.params.employeeId;
  },
})
export default class EmployeeStatisticsIndex extends Vue {
  employeeId = '';
  isAddShow = false;
  isLoadingAddress = false;
  minCoordinates: [number?, number?] = [];
  maxCoordinates: [number?, number?] = [];

  get currentTab(): string {
    return EmployeeEntityModule.tabsNav.points?.id;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get points(): Record<string, PointInterface> {
    return EmployeeStatisticsEntityModule.model.points;
  }

  get shops(): Record<string, ShopListInterface> {
    return EmployeeStatisticsEntityModule.model.shops;
  }

  get modalParams(): ModalParamsInterface {
    return EmployeeStatisticsModule.modalParams;
  }

  get coordinates(): { latitude: number; longitude: number } {
    return EmployeeStatisticsEntityModule.model.addressCoordinates.value as { latitude: number; longitude: number };
  }

  get shopStatuses(): typeof ShopStatus {
    return ShopStatus;
  }

  get usePermission(): boolean {
    return userModule.userHasPermission('CAN_READ_CLIENT_MARKET');
  }

  async initSuitableShopsMap(): Promise<void> {
    const mapWrapper = document.getElementById('shops_map');
    const mapPointsWrapper = document.getElementById('map');

    if (!mapWrapper) {
      const that = this;
      setTimeout(function () {
        that.initSuitableShopsMap();
      }, 200);

      return;
    }
    if (mapPointsWrapper) {
      mapPointsWrapper.style.display = 'none';
    }

    const height = document.getElementsByClassName('cp-points-list__wrapper')[0].clientHeight - 48;
    mapWrapper.style.height = `${height}px`;

    await this.initSuitableShopsMapHelper();

    const lat: number[] = [];
    const lng: number[] = [];
    let coordinates: [number, number];
    for (coordinates of Object.values(suitableShopsMapHelper.entity.geoObjects.getBounds()) as [number, number][]) {
      lat.push(coordinates[0]);
      lng.push(coordinates[1]);
    }
    this.minCoordinates = [Math.min(...lat), Math.min(...lng)];
    this.maxCoordinates = [Math.max(...lat), Math.max(...lng)];

    suitableShopsMap = new YandexMap();
    const mapParams = suitableShopsMap.getCenterAndZoom([this.minCoordinates, this.maxCoordinates]);
    suitableShopsMap.initMap({ latitude: mapParams.center[0], longitude: mapParams.center[1] }, 'shops_map', mapParams.zoom);

    suitableShopsMap.initShops(this.shops);
    suitableShopsMap.createCirclesByPoints(this.points);
  }

  initSuitableShopsMapHelper(): void {
    this.minCoordinates = [];
    this.maxCoordinates = [];
    suitableShopsMapHelper = new YandexMap();

    const lat: number[] = [];
    const lng: number[] = [];
    for (const shop of Object.values(this.shops)) {
      if (!shop.addressCoordinates?.latitude) {
        continue;
      }

      lat.push(shop.addressCoordinates.latitude);
      lng.push(shop.addressCoordinates.longitude);
    }

    //bounds
    for (const point of Object.values(this.points)) {
      if (!point.addressCoordinates?.latitude) {
        continue;
      }

      lat.push(point.addressCoordinates.latitude);
      lng.push(point.addressCoordinates.longitude);
    }

    this.minCoordinates = [Math.min(...lat), Math.min(...lng)];
    this.maxCoordinates = [Math.max(...lat), Math.max(...lng)];

    const mapParams = suitableShopsMapHelper.getCenterAndZoom([this.minCoordinates, this.maxCoordinates]);
    suitableShopsMapHelper.initMap(
      { latitude: mapParams.center[0], longitude: mapParams.center[1] },
      'shops_map_hidden',
      mapParams.zoom
    );

    suitableShopsMapHelper.initShops(this.shops);
    suitableShopsMapHelper.createCirclesByPoints(this.points);
  }

  updateShopMarkerColor(value: { target: { checked: boolean } }, params: { id: string }): void {
    suitableShopsMap.updateShopPlaceMarkColor(params.id, value.target.checked);

    EmployeeStatisticsEntityModule.updateIsActiveStatus({
      id: params.id,
      bool: value.target.checked,
    });
  }

  toAdd(): void {
    EmployeeStatisticsModule.showModal('add');
    this.resetMapsWrappers();

    EmployeeStatisticsEntityModule.updateAddressCoordinates(EmployeeStatisticsEntityModule.model.addressCoordinates.default);
    this.isAddShow = true;
    yandexMap = Object.create(null);
    suitableShopsMap = Object.create(null);

    const that = this;
    setTimeout(function () {
      that.initMap();
    }, 0);
  }

  async editPoint(point: Record<string, string | number | Record<string, number>>): Promise<void> {
    await this.resetMapsWrappers();

    const that = this;

    suitableShopsMap = Object.create(null);
    yandexMap = Object.create(null);
    that.isAddShow = true;
    await EmployeeStatisticsEntityModule.initEditPoint(point).then(() => {
      setTimeout(function () {
        that.initMap();
      }, 0);
    });

    await EmployeeStatisticsModule.showModal('edit');
  }

  errorAddressRequest = 0;
  getPointName(point: PointInterface): string {
    if (point.name || point.address) {
      return point.name ? point.name : point.address;
    }

    const MAX_ERROR_COUNT = 3;
    if (!this.isLoadingAddress && this.errorAddressRequest < MAX_ERROR_COUNT) {
      this.updateSelectedAddress(point);
    }

    return point.address ? point.address : 'Адрес не указан';
  }

  async updateSelectedAddress(point: PointInterface): Promise<void> {
    if (point.addressCoordinates?.latitude && point.addressCoordinates?.longitude) {
      try {
        this.isLoadingAddress = true;
        await EmployeeStatisticsEntityModule.updatePointAddress(point);
      } catch (err) {
        this.errorAddressRequest++;
        console.error('Error: ', err);
      } finally {
        this.isLoadingAddress = false;
      }
    }
  }

  closeModal(): void {
    EmployeeStatisticsModule.hideModal('add');
    EmployeeStatisticsModule.hideModal('edit');
    this.returnToList();
  }

  resetMapsWrappers(): void {
    const shopsMapElement = document.getElementById('shops_map');
    if (shopsMapElement) {
      shopsMapElement.innerHTML = '';
    }
    this.resetShopMapWrapper();
  }

  resetShopMapWrapper(): void {
    const shopMapElement = document.getElementById('map');
    if (shopMapElement) {
      shopMapElement.innerHTML = '';
    }
  }

  initMap(): void {
    const mapWrapper = document.getElementById('map');
    if (!mapWrapper) {
      const that = this;
      setTimeout(function () {
        that.initMap();
      }, 200);

      return;
    }
    const height = document.getElementsByClassName('cp-point__shops')[0].clientHeight - 56;
    mapWrapper.style.height = `${height}px`;
    mapWrapper.style.display = 'block';

    yandexMap = new YandexMap();
    yandexMap.initMap(this.coordinates, 'map');
    if (this.coordinates.latitude && this.coordinates.longitude) {
      yandexMap.addPlaceMarker(this.coordinates);
    }

    yandexMap.addEventsClick();
  }

  returnToList(): void {
    this.isAddShow = false;
    yandexMap = Object.create(null);
    this.resetShopMapWrapper();

    const that = this;
    EmployeeStatisticsEntityModule.initPoints(this.employeeId).then(() => {
      setTimeout(function () {
        that.initSuitableShopsMap();
      }, 0);
    });
  }

  save(): void {
    EmployeeStatisticsEntityModule.saveUpdate(this.employeeId);
  }

  updatePointCoordinates(event: unknown): void {
    EmployeeStatisticsEntityModule.updateSelectedAddress({
      latitude: (event as { detail: [number, number] }).detail[0],
      longitude: (event as { detail: [number, number] }).detail[1],
    });

    EmployeeStatisticsEntityModule.updateAddressCoordinates({
      latitude: (event as { detail: [number, number] }).detail[0],
      longitude: (event as { detail: [number, number] }).detail[1],
    });
  }

  mounted(): void {
    if (EmployeesModule.pageSettings.titleEdit === '') {
      EmployeeEntityModule.initEdit(this.employeeId);
    }

    const that = this;
    EmployeeStatisticsEntityModule.initPoints(this.employeeId).then(() => {
      setTimeout(function () {
        that.initSuitableShopsMap();
      }, 0);
    });
  }

  created(): void {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }

  updated(): void {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }

  unmounted(): void {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }
}
